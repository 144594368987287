import request from '@/utils/request'
export function case_List(data) {
  return request({
    url: '/case/review/querylist',
    method: 'get',
    params:data
  })
}

export function case_detiles(id) {
    return request({
      url: `/case/review/detail?id=${id}`,
      method: 'get',
    })
  }

  export function case_fb(id) { //发布
    return request({
      url: `/case/review/updateShowStatus?id=${id}`,
      method: 'get',
    })
  }

  export function case_del(id) { //删除
    return request({
      url: `/case/review/delete?id=${id}`,
      method: 'get',
    })
  }
  